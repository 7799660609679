import './styles.css';
import OptionalInput from '../OptionalInput';
import Counter from '../Counter';
import Note from '../Note';
import Modal from '../Modal';
import { useModalState } from 'hooks';
import { useTranslation } from 'react-i18next';

import type { Bend } from 'components/MultiStepForm/types';

interface AttachmentCardProps extends Bend {
  quantity: number;
  coverQuantity: number;
  hasCouplers: boolean;
  setBendCount: (
    bendOrCover: 'bend' | 'cover',
    id: string,
    name: string,
    count: number
  ) => void;
}

const ExtendedCouplerImageMapping: Record<string, string> = {
  T01: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/e2665412bff84a60bdca01a01c62220c.png',
  T02: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/e76550e32aff4f64a702dd0a70f52c11.png',
  'Edged Corner':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/e25fdd80fd7d498b810fc691a8811163.png',
  'Straight Corner':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/2a7d63e9ad75438e8fc792d0ad0c5677.png',
  'Straigth corner':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/2a7d63e9ad75438e8fc792d0ad0c5677.png',
  'C1 + C2 + C3':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/cb748fa9f9024c4b86942318ba647d80.png',
  'Right angle flat bend':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/2a7d63e9ad75438e8fc792d0ad0c5677.png',
  'Right angle flat bend1':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/e25fdd80fd7d498b810fc691a8811163.png',
  'T piece':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/e2665412bff84a60bdca01a01c62220c.png',
  'T piece1':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/e76550e32aff4f64a702dd0a70f52c11.png',
  'Wide corner':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/a23e5ba14265495ca563e6a8d2544972.png',
  'Wide corner1':
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/a23e5ba14265495ca563e6a8d2544972.png',
  Reducer:
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/c4b507ab3b554fcb8c8280b475253c90.png',
  Reducer1:
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/e9818d5da4d440cdbd09134703e56fe4.png',
  Raisers:
    'https://nu-platform-prod-2.storage.googleapis.com/gallery/143cef4b45d14c00ac6dcc15dbf73ff9.png',
};

const AttachmentCard = ({
  cr,
  type,
  name,
  quantity,
  coverQuantity,
  imageUrl,
  setBendCount,
  hasCover,
  hasCouplers,
}: AttachmentCardProps) => {
  const { t } = useTranslation();
  const [isOpen, open, close] = useModalState(false);

  return (
    <>
      <div className="attachment-card">
        <strong>
          {type} - {name}
        </strong>
        <div>
          <div className="attachment-image center-x">
            <img src={imageUrl} alt="" />
            {ExtendedCouplerImageMapping[name] && hasCouplers && (
              <>
                <img src={ExtendedCouplerImageMapping[name]} alt="" />
                <span onClick={open}>{t('backend.installation.guide')}</span>
              </>
            )}
          </div>
          <div className="attachment-quantity-counters">
            <Counter
              count={quantity}
              increment={() => setBendCount('bend', cr, name, quantity + 1)}
              decrement={() => setBendCount('bend', cr, name, quantity - 1)}
              onInputChange={(val) => setBendCount('bend', cr, name, val)}
            />
            {hasCover ? (
              <OptionalInput
                isInitiallyOpen={coverQuantity > 0}
                toggleText={
                  <span className="include-lid">
                    {t('selected.coupler.bend.item.include.lid')}
                  </span>
                }
              >
                <Counter
                  count={coverQuantity}
                  increment={() =>
                    setBendCount('cover', cr, name, coverQuantity + 1)
                  }
                  decrement={() =>
                    setBendCount('cover', cr, name, coverQuantity - 1)
                  }
                  onInputChange={(val) => setBendCount('cover', cr, name, val)}
                />
              </OptionalInput>
            ) : (
              <div style={{ width: 190 }}></div>
            )}
          </div>

          <div
            className="alert"
            style={{
              visibility: quantity < coverQuantity ? 'visible' : 'hidden',
            }}
          >
            <Note
              iconColor="#fcb900"
              text={t('validate.bend.quantities.msg')
                .replace(/#coverQuantity/g, quantity.toString())
                .replace(/#bendQuantity/g, coverQuantity.toString())}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen}>
        <Modal.Header onClose={close}>{name}</Modal.Header>
        <Modal.Content>
          {ExtendedCouplerImageMapping[name] && hasCouplers && (
            <img
              src={ExtendedCouplerImageMapping[name]}
              alt={'coupler-img'}
              width="100%"
            />
          )}
        </Modal.Content>
        <Modal.Footer style={{ flexDirection: 'row-reverse' }}>
          <button className="btn-tertiary">{t('global.close')}</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttachmentCard;
