import './styles.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ProductThumbnail } from 'shared/components';
import { SinglePendant, DoublePendant } from 'assets/icons/pendants';
import { useMyContext } from 'helpers';
import { Modal } from 'shared/components';
import ExistingPendantsModal from '../ExistingPendantsModal';
import ReactGA from 'react-ga4';
import { Help } from 'shared/components';

import type { Pendant } from 'components/MultiStepForm/types';

interface IPendantSteps {
  pendants: Pendant[];
  supportDistances: string[];
  step: number;
  onClose: () => void;
  goBack: () => void;
  goForth: () => void;
  setStep: (step: 1 | 2 | 3) => void;
}

const PendantSteps = ({
  pendants,
  step,
  onClose,
  goBack,
  goForth,
  setStep,
  supportDistances,
}: IPendantSteps) => {
  const { t } = useTranslation();
  const { currentConfiguration, setConfig, createProjectItem, currentProject } =
    useMyContext<3>();
  const {
    pendantShape,
    pendantHookCount,
    pendantDutyType,
    pendant,
    pendantSupportDistance,
    pendantProjectName,
  } = currentConfiguration.data;
  const [name, setName] = useState(pendantProjectName || '');
  const [selectedPendantCr, setSelectedPendantCr] = useState(pendant || '');
  const [isExistingPendantsModalOpen, setIsOpen] = useState(
    !!currentProject.pendants.length
  );

  const {
    OnePendant,
    TwoPendants,
    ThreePendants,
  } = require(`assets/icons/pendants/${pendantShape || 'single'}`);

  const pendantTitles = {
    2: t('pendant.modal.header'),
    3: t('pendant.modal.type.header'),
    4: t('pendant.modal.details.header'),
  };

  const selectedPendantVariations = pendants
    .filter((p) => pendantDutyType === p.dutyType)
    .filter((v, i, a) => a.findIndex((v2) => v2.cr === v.cr) === i);

  const selectedPendant =
    selectedPendantVariations.find((p) => p.cr === selectedPendantCr) ||
    selectedPendantVariations[0];

  const isDisabled = () => {
    if (step === 2) {
      return !name || !pendantShape || !pendantHookCount;
    }
    if (step === 3) {
      return !pendantDutyType;
    }
  };

  return isExistingPendantsModalOpen ? (
    <ExistingPendantsModal
      isOpen
      onClose={onClose}
      showAddPendantModal={() => setIsOpen(false)}
    />
  ) : (
    <>
      <Modal.Header onClose={onClose}>
        {pendantTitles[step as keyof typeof pendantTitles]}
      </Modal.Header>
      <Modal.Content style={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
        {step === 2 && (
          <>
            <label>
              <strong style={{ display: 'flex' }}>
                <div style={{ marginRight: '0.5rem' }}>
                  {t('pendant.modal.pendant.name')}
                </div>
                <Help
                  position={{
                    left: 15,
                  }}
                  withBorder
                >
                  <p>{t('pendant.steps.new.config.trace.msg')}</p>
                </Help>
              </strong>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <strong>
              {t('pendant.modal.single.or.double.pendant.question')}
            </strong>
            <div className="pendant-configurations">
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_shape',
                    label: 'single',
                  });
                  setConfig({ pendantShape: 'single' }, false);
                }}
              >
                <p>{t('pendant.modal.single')}</p>
                <ProductThumbnail
                  img={<SinglePendant />}
                  selected={pendantShape === 'single'}
                />
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_shape',
                    label: 'double',
                  });
                  setConfig({ pendantShape: 'double' }, false);
                }}
              >
                <p>{t('pendant.modal.double')}</p>
                <ProductThumbnail
                  img={<DoublePendant />}
                  selected={pendantShape === 'double'}
                />
              </div>
            </div>
            <strong>{t('pendant.modal.pendant.qty.question')}</strong>
            <div className="pendant-configurations">
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_hook_count',
                    label: '1',
                  });
                  setConfig({ pendantHookCount: 1 }, false);
                }}
              >
                <p>{t('pendant.modal.pendant.qty.single')}</p>
                <ProductThumbnail
                  img={<OnePendant />}
                  selected={pendantHookCount === 1}
                />
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_hook_count',
                    label: '2',
                  });
                  setConfig({ pendantHookCount: 2 }, false);
                }}
              >
                <p>{t('pendant.modal.pendant.qty.double')}</p>
                <ProductThumbnail
                  img={<TwoPendants />}
                  selected={pendantHookCount === 2}
                />
              </div>
              <div
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.pendantStep2',
                    action: 'pendant_hook_count',
                    label: '3',
                  });
                  setConfig({ pendantHookCount: 3 }, false);
                }}
              >
                <p>{t('pendant.modal.pendant.qty.triple')}</p>
                <ProductThumbnail
                  img={<ThreePendants />}
                  selected={pendantHookCount === 3}
                />
              </div>
            </div>
          </>
        )}
        {step === 3 && pendantHookCount && (
          <>
            {pendants
              .filter((p) =>
                pendantShape === 'double'
                  ? p.supportsDoubleSided
                  : !p.supportsDoubleSided
              )
              .filter(
                (p) =>
                  parseInt(p.maxSingleSided) >= pendantHookCount ||
                  parseInt(p.maxDoubleSided) >= pendantHookCount
              )
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.dutyType === value.dutyType)
              )
              .map(({ dutyType, imageUrl, cr }) => (
                <div
                  key={`option-${dutyType}-${cr}`}
                  className="additional-option"
                  style={{
                    borderColor:
                      pendantDutyType === dutyType ? 'var(--accent-color)' : '',
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'CableTracker.pendantStep3',
                      action: 'pendant_duty_type',
                      label: dutyType,
                    });
                    setConfig({ pendantDutyType: dutyType }, false);
                  }}
                >
                  <img src={imageUrl} alt="" />
                  <div>
                    <strong>{dutyType}</strong>
                    <p></p>
                  </div>
                </div>
              ))}
          </>
        )}
        {step === 4 && (
          <>
            <div className="pendant-details">
              <div className="image-container">
                <img src={selectedPendant?.imageUrl} alt="" />
              </div>
              <div>
                <h4>{selectedPendant?.crDescription}</h4>
                <div className="sizes-container">
                  <p>{t('global.measure.length.mm')}</p>
                  {selectedPendantVariations.map((p) => (
                    <span
                      key={`option-${p.cr}-${p.length}`}
                      className={`size-box${
                        p.cr === selectedPendant.cr ? '--selected' : ''
                      }`}
                      onClick={(e) => {
                        ReactGA.event({
                          category: 'CableTracker.pendantStep4',
                          action: 'selected_pendant_cr',
                          label: p.cr,
                        });
                        setSelectedPendantCr(p.cr);
                      }}
                    >
                      {p.length}
                    </span>
                  ))}
                </div>
                <div className="sizes-container">
                  <p>{t('suspension.modal.suspension.distance.m')}</p>
                  {supportDistances?.map((d) => (
                    <span
                      key={`option-${d}`}
                      className={`size-box${
                        (
                          pendantSupportDistance
                            ? d === pendantSupportDistance
                            : d === supportDistances[0]
                        )
                          ? '--selected'
                          : ''
                      }`}
                      onClick={(e) => {
                        ReactGA.event({
                          category: 'CableTracker.pendantStep4',
                          action: 'selected_pendant_support_distance',
                          label: d,
                        });
                        setConfig({ pendantSupportDistance: d }, false);
                      }}
                    >
                      {d}
                    </span>
                  ))}
                </div>
                <p className="text-secondary">
                  {t('pendant.modal.min.num.of.brackets.text')}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <button onClick={onClose} className="btn-tertiary">
          {t('global.cancel')}
        </button>
        <span>
          {step > 1 && (
            <button className="btn-secondary" onClick={goBack}>
              {t('global.previous')}
            </button>
          )}
          {step < 4 && (
            <button
              onClick={() => {
                goForth();
                step === 2 && setConfig({ pendantProjectName: name }, false);
              }}
              disabled={isDisabled()}
            >
              {t('global.next')}
            </button>
          )}
          {step === 4 && (
            <button
              onClick={(e) => {
                ReactGA.event({
                  category: 'CableTracker.pendantStep4',
                  action: 'click',
                  label: 'finish_project',
                });
                e.stopPropagation();
                setConfig(
                  {
                    pendant: selectedPendant.cr,
                    pendantSupportDistance:
                      pendantSupportDistance ||
                      selectedPendant.supportDistances[0],
                  },
                  false
                );
                createProjectItem(
                  'pendants',
                  currentProject.id,
                  pendantProjectName!,
                  [currentConfiguration.name]
                );
                setStep(2);
              }}
            >
              {t('global.finished')}
            </button>
          )}
        </span>
      </Modal.Footer>
    </>
  );
};

export default PendantSteps;
