import './styles.css';
import { AiOutlinePlus } from 'react-icons/ai';
import { useContext } from 'react';

import MyProject from './components/MyProject';
import { Modal } from 'shared/components';
import { useModalState } from 'hooks';
import NewProjectModal from '../NewProjectModal';
import { ProjectsContext } from 'contexts/projectsContext';
import ExistingProjectModal from '../ExistingProjectModal';

import { useTranslation } from 'react-i18next';

import type { ModalProps } from 'shared/types';

const MyProjects = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const [modal, openModal, closeModal] = useModalState({
    new: false,
    existing: false,
  });
  const { projects } = useContext(ProjectsContext);

  return (
    <Modal isOpen={isOpen} className="my-projects-modal">
      <Modal.Header onClose={onClose}>
        {t('backend.add.new.project')}
      </Modal.Header>
      <Modal.Content>
        <div className="add-new-project" onClick={() => openModal('new')}>
          <AiOutlinePlus />
          {t('project.details.add.project')}
        </div>
        <div className="my-projects-container">
          {projects.map((project) => (
            <MyProject
              key={`option-${project.name}-${project.id}`}
              {...project}
              onClose={onClose}
            />
          ))}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <button className="btn-tertiary" onClick={onClose}>
          {t('global.close')}
        </button>
        <button onClick={() => openModal('existing')}>
          {t('project.modal.apply.project.code')}
        </button>
      </Modal.Footer>

      <NewProjectModal
        onlyCloseButton
        isOpen={modal.new}
        onClose={() => closeModal('new')}
      />
      <ExistingProjectModal
        isOpen={modal.existing}
        onClose={() => closeModal('existing')}
      />
    </Modal>
  );
};

export default MyProjects;
