import './styles.css';
import { Modal, Note } from 'shared/components';
import { useMyContext, getProject } from 'helpers';
import type { ModalProps } from 'shared/types';
import { useTranslation } from 'react-i18next';

interface PendantInfoModalProps extends ModalProps {
  id: string;
  parentId: string;
}

const PendantInfoModal = ({
  id,
  parentId,
  isOpen,
  onClose,
}: PendantInfoModalProps) => {
  const { t } = useTranslation();
  const { projects } = useMyContext<5>();
  const selectedPendant = getProject<5>(projects, parentId, 'pendants', id);
  let noteMessage = ' ' + t('pendant.modal.routes.added.to.suspension');
  return (
    <Modal isOpen={isOpen} className="pendant-info-modal">
      <Modal.Header onClose={onClose}>
        {t('pendant.modal.create.or.edit')}
      </Modal.Header>
      <Modal.Content>
        <Note text={selectedPendant?.configurations.length + noteMessage} />
        <strong>{t('global.pendant.title')}</strong>
        <div className="pendant-info">
          <img
            src="https://www.technischeunie.nl/images/artikel/483198.gif"
            alt={'pendant-img'}
          />
          <div>
            <div>HANGCONSOLE 2F-1000 TV</div>
            <strong className="text-secondary">
              {t('backend.vertical.piece.2f.light.duty')}
            </strong>
          </div>
        </div>

        <strong>{t('pendant.modal.routes')}</strong>
        <div className="pendant-configs">
          {selectedPendant?.configurations.map(({ name, data }) => (
            <div className="pendant-config">
              <div className="config-name">{name}</div>
              <div className="configuration-info">
                <div>
                  <strong className="text-secondary">
                    {t('global.length')}: {data.length || 0}
                  </strong>
                </div>

                <div className="pendant-info">
                  <img src={data.gutterObj.imageUrl} alt={'pendant-img'} />
                  <div>
                    <div>{data.gutterObj.crDescription}</div>
                    <strong className="text-secondary">
                      {t('global.width')}:{data.size?.split('x')[1]}
                    </strong>
                  </div>
                </div>

                {data.suspension && (
                  <div className="pendant-info">
                    <img
                      src={data.suspensionObj?.imageUrl}
                      alt={'pendant-img'}
                    />
                    <div>
                      <div>{data.suspensionObj?.crDescription}</div>
                      <strong className="text-secondary">
                        {t('global.width')}:{data.suspensionObj?.width}
                      </strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <button className="btn-tertiary" onClick={onClose}>
          {t('global.close')}
        </button>
        <button>{t('pendant.modal.mark.as.complete')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default PendantInfoModal;
