import './styles.css';
import { useCallback, useEffect } from 'react';
import { useModalState } from 'hooks';
import CouplersAndGuttersModal from '../CouplersAndGuttersModal';

import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import type { Dimension } from '../../types';
import { useMyContext } from 'helpers';

interface GutterSizePickerProps {
  sizes: { w: number; h: number }[];
  currentSize: string;
  onSelectSize: (size: string) => void;
  step3Data: Dimension[];
}

const GutterSizePicker = ({
  sizes,
  currentSize,
  onSelectSize,
  step3Data,
}: GutterSizePickerProps) => {
  const { t } = useTranslation();
  const { setConfig, currentConfiguration } = useMyContext<3>();
  const [isOpen, open, close] = useModalState(false);

  const widths = [...new Set(sizes.map((s) => s.w))].sort((a, b) => a - b);
  const heights = [...new Set(sizes.map((s) => s.h))].sort((a, b) => a - b);

  const preselect = useCallback(
    (size: string) => {
      const [data] = step3Data.filter((s3t) => s3t.dimensions === size);

      const defaultCoupler = data?.availableTrays[0].couplers.find(
        (c) => c.isDefault
      );

      setConfig(
        {
          gutter: data.availableTrays[0].cr,
          coupler: defaultCoupler
            ? defaultCoupler.cr
            : data.availableTrays[0].couplers[0].cr,
        },
        false
      );
    },
    [setConfig, step3Data]
  );

  useEffect(() => {
    const { gutter, coupler } = currentConfiguration.data;
    !gutter && !coupler && preselect(currentSize);
  }, [currentConfiguration.data, currentSize, preselect]);

  return (
    <>
      <div className="gutter-sizes">
        <h4>{t('main.step3.gutter.size')}</h4>
        {widths.map((w) => (
          <div key={`option-${w}`}>
            {heights.map((h) => {
              const size = sizes.find((size) => size.h === h && size.w === w);
              const isSelected = currentSize === `${w} x ${h}`;

              return size ? (
                <div
                  key={`option-${w}-${h}`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'CableTracker.size',
                      action: 'click',
                      label: 'size_w_' + w + '_h_' + h,
                    });
                    onSelectSize(`${w} x ${h}`);
                    preselect(`${w} x ${h}`);
                  }}
                  className={`size-box${isSelected ? '--selected' : ''}`}
                >
                  {size.w} x {size.h}
                </div>
              ) : (
                <div style={{ borderColor: 'transparent' }}></div>
              );
            })}
          </div>
        ))}
        <div
          className="additional-options"
          onClick={(e) => {
            ReactGA.event({
              category: 'CableTracker.additionalOptions',
              action: 'click',
            });
            e.preventDefault();
            open();
          }}
        >
          {t('main.step3.include.additionaloptions')}
        </div>
      </div>

      <CouplersAndGuttersModal
        isOpen={isOpen}
        onClose={close}
        step3Data={step3Data.filter((s3t) => s3t.dimensions === currentSize)}
      />
    </>
  );
};

export default GutterSizePicker;
