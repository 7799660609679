import './styles.css';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { useContext } from 'react';
import { ContactInfoModal, MyProjectsModal } from 'components';
import { useModalState } from 'hooks';
import { ProjectsContext } from 'contexts/projectsContext';
import { FolderFull } from 'assets/icons/file';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();
  const [isContactInfoOpen, openContactInfo, closeContactInfo] =
    useModalState(false);
  const [isMyProjectsModalOpen, openMyProjectsModal, closeMyProjectsModal] =
    useModalState(false);
  const { currentProject, currentConfiguration } = useContext(ProjectsContext);

  return (
    <nav>
      <img
        src="https://nu-platform-prod-2.storage.googleapis.com/gallery/a563b26ed3a44afeab7b6bc7fed51fab.png"
        alt=""
      />
      <div onClick={openContactInfo}>
        <TfiHeadphoneAlt />
        {t('header.contact.support')}
      </div>
      <div>
        {currentProject && (
          <>
            {currentProject?.name} / {currentConfiguration?.name}
          </>
        )}
        <div className="my-projects center-xy" onClick={openMyProjectsModal}>
          <FolderFull />
        </div>
        {t('global.my.projects')}
      </div>

      <ContactInfoModal isOpen={isContactInfoOpen} onClose={closeContactInfo} />
      <MyProjectsModal
        isOpen={isMyProjectsModalOpen}
        onClose={closeMyProjectsModal}
      />
    </nav>
  );
};

export default Navbar;
