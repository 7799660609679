import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { getProject } from 'helpers';
import ReactGA from 'react-ga4';

import type {
  Project,
  ProjectsCRUD,
  ProjectsContextValue,
  MultiStepFormState,
  LegacyProject,
  SubProject,
  Configuration,
} from 'shared/types';

export const ProjectsContext = createContext({} as ProjectsContextValue);

interface ProjectsProviderProps {
  children: React.ReactNode;
}

const getConfig = (
  projects: Project[],
  currentProjectId: string | null,
  currentConfigurationId: string | null,
  subProjectType: 'subProjects' | 'pendants',
  currentSubProjectId: string | null
) => {
  const currentProject = getProject(
    projects,
    currentProjectId,
    subProjectType,
    currentSubProjectId
  );

  return (
    currentProject?.configurations.find(
      (conf) => conf.id === currentConfigurationId
    ) || null
  );
};

export const ProjectsProvider = ({ children }: ProjectsProviderProps) => {
  const [projects, setProjects] = useState<Project[]>(
    JSON.parse(localStorage.getItem('projects') || '[]')
  );
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(
    JSON.parse(localStorage.getItem('currentProjectId') || 'null')
  );
  const [currentSubProjectId, setCurrentSubProjectId] = useState<string | null>(
    JSON.parse(localStorage.getItem('currentSubProjectId') || 'null')
  );
  const [currentPendantId, setCurrentPendantId] = useState<string | null>(
    JSON.parse(localStorage.getItem('currentPendantId') || 'null')
  );
  const [currentConfigurationId, setCurrentConfigurationId] = useState<
    string | null
  >(JSON.parse(localStorage.getItem('currentConfigurationId') || 'null'));
  const [step, setCurrentStep] = useState(1);
  const [previousStep, setPreviousStep] = useState(1);

  const setStep = (step: number | ((prev: number) => number)) => {
    setCurrentStep((prev) => {
      prev < 5 && setPreviousStep(prev);
      return typeof step === 'number' ? step : step(prev);
    });
  };

  const createProject: ProjectsCRUD['createProject'] = async (
    name,
    configuration
  ) => {
    const payload = {
      id: nanoid(),
      name,
      configurations: [
        { id: nanoid(), name: configuration, data: { material: null } },
      ],
      subProjects: [],
      pendants: [],
      activeConfig: configuration,
    };

    try {
      const res = await axios.post(
        'https://technischeuni.sesmartconfig.com/cable/state',
        { payload: JSON.stringify(payload) }
      );
      const projectId = res.data.configurationId as string;

      setProjects((projects) => [
        ...projects,
        { ...payload, remoteId: projectId },
      ]);
      setCurrentProjectId(payload.id);
      setCurrentConfigurationId(payload.configurations[0].id);
      updateRemoteState({
        ...payload,
        remoteId: projectId,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const oldToNew = {
    dataChannelCr: 'dataGutter',
    selectedAccessories: 'accessories',
    selectedBends: 'bends',
    selectedClassification: 'material',
    selectedCouplerCr: 'coupler',
    selectedCoverCr: 'lid',
    selectedProductSerie: 'shape',
    selectedSuspension: 'suspension',
    selectedSuspensionObject: 'suspensionObj',
    selectedTray: 'gutter',
    selectedTrayObject: 'gutterObj',
    selectedWallDividerCr: 'divider',
    suspensionBracketSize: 'suspensionDistance',
    trayCover: 'lidLength',
    trayCoverJoint: 'coverJointLength',
    trayLength: 'length',
    trayWallDivider: 'dividerLength',
    trayWidth: 'size',
  } as const;

  type ResKey = [
    keyof typeof oldToNew,
    (typeof oldToNew)[keyof typeof oldToNew]
  ];

  const newToOldConfigMapper = (configs: Configuration[]) =>
    configs.map((conf) => {
      const result = {} as LegacyProject['configurations'][number];

      Object.entries(conf.data).forEach(([key, val]) => {
        const resKey = Object.entries(oldToNew).find(
          ([, confKey]) => key === confKey
        ) as unknown as ResKey;
        if (resKey) {
          if (resKey[0] === 'selectedAccessories') {
            // @ts-ignore
            result[resKey[0]] = val.map((a) => ({
              cr: a.cr,
              quantity: a.quantity,
            }));
          } else if (resKey[0] === 'selectedBends') {
            // @ts-ignore
            result[resKey[0]] = val.map((b) => ({
              cr: b.cr,
              quantity: b.quantity,
              coverQuantity: b.coverQuantity,
              selectedName: b.name,
            }));
          } else {
            // @ts-ignore
            result[resKey[0]] = val;
          }
        } else {
          // @ts-ignore
          result[key] = val;
        }
      });

      return { ...result, name: conf.name, id: conf.id };
    });

  const oldToNewConfigMapper = (configs: LegacyProject['configurations']) =>
    configs.map((conf) => {
      const result = {} as Project['configurations'][number]['data'];

      Object.entries(conf).forEach(([key, val]) => {
        const resKey = Object.entries(oldToNew).find(
          ([resKey]) => key === resKey
        ) as unknown as ResKey;

        if (resKey) {
          // @ts-ignore
          result[resKey[1]] = val;
        } else {
          // @ts-ignore
          result[key] = val;
        }
      });

      return { name: conf.name, id: conf.id, data: result };
    });

  // @ts-ignore
  const getBackwardsCompatibleData: ProjectsContextValue['getBackwardsCompatibleData'] =
    ({ from, project }) => {
      if (from === 'new') {
        const configurations = [
          ...newToOldConfigMapper(project.configurations),
          ...project.subProjects.flatMap((sp) =>
            newToOldConfigMapper(
              sp.configurations.map((c) => ({
                ...c,
                data: { ...c.data, subproject: sp.id },
              }))
            )
          ),
          ...project.pendants.flatMap((p) =>
            newToOldConfigMapper(
              p.configurations.map((c) => ({
                ...c,
                data: { ...c.data, pendantProject: p.id },
              }))
            )
          ),
        ];
        return {
          ...project,
          activeConfiguration: configurations.findIndex(
            (c) => c.id === project.activeConfig
          ),
          // @ts-ignore
          configurations,
          subprojects: project.subProjects.map((sp) => ({
            id: sp.id,
            name: sp.name,
          })),
          pendantProjects: project.pendants.map((p) => ({
            id: p.id,
            name: p.name,
            // @ts-ignore
            bracketDistance: p.configurations[0].data.pendantSupportDistance,
            // @ts-ignore
            selectedPendantId: p.configurations[0].data.pendant,
            // @ts-ignore
            selectedPendantObject: p.configurations[0].data.pendantObj,
            // @ts-ignore
            selectedPendantSupport: p.configurations[0].data.pendantShape,
          })),
        };
      } else {
        const configurations = project.configurations.map((c) => ({
          ...c,
          id: nanoid(),
        }));
        const actualConfigurations = configurations.filter(
          (c) => !('subproject' in c) && !('pendantProject' in c)
        );
        const subProjectConfigurations = configurations.filter(
          (c) => 'subproject' in c
        );
        const pendantConfigurations = configurations.filter(
          (c) => 'pendantProject' in c
        );
        const activeConfiguration =
          typeof project.activeConfiguration === 'number'
            ? configurations[project.activeConfiguration].id
            : project.activeConfiguration;

        const activeConfigObj = configurations.find(
          (c) => c.id === activeConfiguration
        );

        const isSubProject = 'subproject' in (activeConfigObj || {});
        const isPendant = 'pendantProject' in (activeConfigObj || {});

        isSubProject && setCurrentSubProjectId(activeConfigObj?.subproject!);
        isPendant && setCurrentPendantId(activeConfigObj?.pendantProject!);

        return {
          id: project.id || nanoid(),
          name: project.name,
          activeConfig: activeConfiguration,
          configurations: oldToNewConfigMapper(actualConfigurations),
          subProjects:
            project.subprojects?.map((sp) => ({
              id: sp.id,
              name: sp.name,
              configurations: oldToNewConfigMapper(
                subProjectConfigurations.filter((c) => c.subproject === sp.id)
              ),
            })) || [],
          pendants:
            project.pendantProjects?.map((p) => ({
              id: p.id,
              name: p.name,
              configurations: oldToNewConfigMapper(
                pendantConfigurations.filter((c) => c.pendantProject === p.id)
              ).map((c) => ({
                ...c,
                data: {
                  ...c.data,
                  pendant: p.selectedPendantId,
                  pendantSupportDistance: p.bracketDistance,
                },
              })),
            })) || [],
          remoteId: project.remoteId,
        };
      }
    };

  const updateRemoteState = async (newState: Project) => {
    try {
      const { data } = await axios.post(
        'https://technischeuni.sesmartconfig.com/cable/state',
        {
          payload: JSON.stringify(
            getBackwardsCompatibleData({ project: newState, from: 'new' })
          ),
        }
      );

      const projectId = data.configurationId;

      setProjects((projects) =>
        projects.map((project) =>
          project.id === newState.id
            ? { ...project, remoteId: projectId }
            : project
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const editProject: ProjectsCRUD['editProject'] = (id, name) => {
    setProjects((projects) =>
      projects.map((project) => {
        if (project.id === id) {
          const newState = { ...project, name };

          updateRemoteState(newState);
          return newState;
        } else return project;
      })
    );
  };

  const deleteProject: ProjectsCRUD['deleteProject'] = (id) => {
    setProjects((projects) => projects.filter((project) => project.id !== id));
  };

  const duplicateProject: ProjectsCRUD['duplicateProject'] = (id) => {
    setProjects((projects) =>
      projects.flatMap((project) => {
        if (project.id === id) {
          const newState = {
            ...project,
            id: nanoid(),
            name: project.name + ' copy',
          };

          updateRemoteState(newState);
          setCurrentProjectId(newState.id);
          return [project, newState];
        } else return project;
      })
    );
  };

  const createProjectItem: ProjectsCRUD['createProjectItem'] = (
    itemType,
    projectId,
    name,
    configs
  ) => {
    if (itemType === 'configurations') {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            const configId = nanoid();
            const newState = {
              ...project,
              configurations: [
                ...project.configurations,
                { id: configId, name, data: { material: null } },
              ],
              activeConfig: name,
            };

            setStep(1);
            updateRemoteState(newState);
            setCurrentConfigurationId(configId);
            setCurrentSubProjectId(null);
            setCurrentPendantId(null);

            return newState;
          } else return project;
        })
      );
    } else if (itemType === 'subProjects') {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            const supProjectId = nanoid();
            let subprojectConfigs: SubProject['configurations'] = [];
            const projectconfigurations = project.configurations.filter((c) => {
              if (configs?.includes(c.id)) {
                subprojectConfigs.push(c);
                return false;
              }
              return true;
            });

            subprojectConfigs.find((c) => c.id === currentConfigurationId) &&
              setCurrentSubProjectId(supProjectId);

            return {
              ...project,
              configurations: projectconfigurations,
              subProjects: [
                ...project.subProjects,
                {
                  id: supProjectId,
                  name,
                  configurations: subprojectConfigs,
                },
              ],
            };
          } else return project;
        })
      );
    } else {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            const pendantId = nanoid();

            if (!project.configurations.length) {
              const currentConfig = getConfig(
                projects,
                project.id,
                currentConfigurationId,
                'subProjects',
                currentSubProjectId
              );

              const newState = {
                ...project,
                pendants: [
                  ...project.pendants,
                  {
                    id: pendantId,
                    name,
                    configurations: currentConfig ? [{ ...currentConfig }] : [],
                  },
                ],
              };

              updateRemoteState(newState);
              setCurrentPendantId(pendantId);
              setCurrentSubProjectId(null);

              return newState;
            } else {
              const stillInProject = project.configurations.filter(
                (c) => !configs?.includes(c.name)
              );
              const newState = {
                ...project,
                configurations: stillInProject,
                pendants: [
                  ...project.pendants,
                  {
                    id: pendantId,
                    name,
                    configurations: project.configurations.filter((c) =>
                      configs?.includes(c.name)
                    ),
                  },
                ],
              };

              updateRemoteState(newState);
              setCurrentPendantId(pendantId);
              return newState;
            }
          } else return project;
        })
      );
    }
  };

  const editProjectItem: ProjectsCRUD['editProjectItem'] = (
    itemType,
    projectId,
    itemId,
    name,
    subProjectId,
    configs
  ) => {
    if (itemType === 'configurations') {
      if (subProjectId && configs && configs[0]) {
        const parentType =
          configs[0] === 'pendants' ? 'pendants' : 'subProjects';

        setProjects((projects) =>
          projects.map((project) => {
            if (project.id === projectId) {
              const newState = {
                ...project,
                [parentType]: project[parentType].map((subProject) => {
                  if (subProject.id === subProjectId) {
                    return {
                      ...subProject,
                      configurations: subProject.configurations.map((config) =>
                        config.id === itemId ? { ...config, name } : config
                      ),
                    };
                  } else return subProject;
                }),
                activeConfig: name,
              };

              updateRemoteState(newState);
              return newState;
            } else return project;
          })
        );
      } else {
        setProjects((projects) =>
          projects.map((project) => {
            if (project.id === projectId) {
              const newState = {
                ...project,
                configurations: project.configurations.map((conf) =>
                  conf.id === itemId ? { ...conf, name } : conf
                ),
                activeConfig: name,
              };

              updateRemoteState(newState);
              return newState;
            } else return project;
          })
        );
      }
    } else {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            let newProjectConfigurationsState: Configuration[] = [];

            const newState = {
              ...project,
              [itemType]: project[itemType].map((subProject) => {
                if (subProject.id === subProjectId) {
                  let removedFromSubProject: Configuration[] = [];
                  const stillInSubProject = subProject.configurations.filter(
                    (c) => {
                      if (configs?.includes(c.id)) return true;
                      else {
                        removedFromSubProject.push(c);
                        return false;
                      }
                    }
                  );

                  const removedFromProject: Configuration[] = [];
                  const stillInProject = project.configurations.filter((c) => {
                    if (!configs?.includes(c.id)) return true;
                    else {
                      removedFromProject.push(c);
                      return false;
                    }
                  });

                  newProjectConfigurationsState = [
                    ...stillInProject,
                    ...removedFromSubProject,
                  ];

                  return {
                    ...subProject,
                    name,
                    configurations: [
                      ...stillInSubProject,
                      ...removedFromProject,
                    ],
                  };
                } else return subProject;
              }),
            };

            newState.configurations = newProjectConfigurationsState;

            if (
              newProjectConfigurationsState.find(
                (c) => c.id === currentConfigurationId
              )
            )
              setCurrentSubProjectId(null);
            else {
              subProjectId &&
                (itemType === 'pendants'
                  ? setCurrentPendantId(subProjectId)
                  : setCurrentSubProjectId(subProjectId));
            }

            updateRemoteState(newState);
            return newState;
          } else return project;
        })
      );
    }
  };

  const deleteProjectItem: ProjectsCRUD['deleteProjectItem'] = (
    itemType,
    projectId,
    itemId,
    subProjectId,
    parentType
  ) => {
    if (subProjectId && parentType) {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            const newState = {
              ...project,
              [parentType]: project[parentType].map((subProject) => {
                if (subProject.id === subProjectId) {
                  return {
                    ...subProject,
                    configurations: subProject.configurations.filter(
                      (config) => config.id !== itemId
                    ),
                  };
                } else return subProject;
              }),
            };

            updateRemoteState(newState);
            return newState;
          } else return project;
        })
      );
    } else {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            const newState = {
              ...project, // @ts-ignore
              [itemType]: project[itemType].filter(
                // @ts-ignore
                (item) => item.id !== itemId
              ),
            };

            updateRemoteState(newState);
            return newState;
          } else return project;
        })
      );
    }
  };

  const duplicateProjectItem: ProjectsCRUD['duplicateProjectItem'] = (
    itemType,
    projectId,
    itemId,
    subProjectId,
    parentType
  ) => {
    if (itemType === 'configurations') {
      if (subProjectId && parentType) {
        setProjects((projects) =>
          projects.map((project) => {
            if (project.id === projectId) {
              let itemName = '';
              const newState = {
                ...project,
                [parentType]: project[parentType].map((subProject) => {
                  if (subProject.id === subProjectId) {
                    return {
                      ...subProject,
                      configurations: subProject.configurations.flatMap(
                        (item) => {
                          if (item.id === itemId) {
                            const newItem = {
                              ...item,
                              id: nanoid(),
                              name: item.name + ' copy',
                            };

                            itemName = item.name;
                            return [item, newItem];
                          } else return item;
                        }
                      ),
                    };
                  } else return subProject;
                }),
              };

              newState.activeConfig = itemName;
              updateRemoteState(newState);

              return newState;
            } else return project;
          })
        );
      } else {
        setProjects((projects) =>
          projects.map((project) => {
            if (project.id === projectId) {
              let itemName = '';
              const newState = {
                ...project,
                configurations: project.configurations.flatMap((item) => {
                  if (item.id === itemId) {
                    const newItem = {
                      ...item,
                      id: nanoid(),
                      name: item.name + ' copy',
                    };

                    itemName = item.name;
                    return [item, newItem];
                  } else return item;
                }),
              };

              newState.activeConfig = itemName;
              updateRemoteState(newState);

              return newState;
            } else return project;
          })
        );
      }
    } else {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === projectId) {
            const subProjectId = nanoid();
            const newState = {
              ...project,
              [itemType]: project[itemType].flatMap((item) => {
                if (item.id === itemId) {
                  const newItem = {
                    ...item,
                    name: item.name + ' copy',
                    id: subProjectId,
                    configurations: item.configurations.map((config) => ({
                      ...config,
                      id: nanoid(),
                    })),
                  };
                  return [item, newItem];
                } else return item;
              }),
            };

            updateRemoteState(newState);
            return newState;
          } else return project;
        })
      );
    }
  };

  const addConfigToPendant: ProjectsContextValue['addConfigToPendant'] = (
    pendantId
  ) => {
    setProjects((projects) =>
      projects.map((project) => {
        if (project.id === currentProjectId) {
          const stillInProject = project.configurations.filter(
            (config) => config.id !== currentConfigurationId
          );
          const selectedConfig = project.configurations.find(
            (config) => config.id === currentConfigurationId
          );
          const newState = {
            ...project,
            configurations: stillInProject,
            pendants: project.pendants.map((pendant) => {
              if (pendant.id === pendantId) {
                return {
                  ...pendant,
                  configurations: selectedConfig
                    ? [...pendant.configurations, selectedConfig]
                    : pendant.configurations,
                };
              } else return pendant;
            }),
          };

          setCurrentPendantId(pendantId);
          updateRemoteState(newState);
          return newState;
        } else return project;
      })
    );
  };

  const loadProject = async (id: string) => {
    try {
      const { data } = await axios.get(
        `https://technischeuni.sesmartconfig.com/cable/state/${id}`
      );

      const project = getBackwardsCompatibleData({
        project: JSON.parse(data.payload),
        from: 'old',
      });
      const doesProjectExist = projects.find((p) => p.id === project.id);

      if (doesProjectExist) {
        const overrideProject = window.confirm(
          'This project already exists. Do you want to overwrite it?'
        );
        if (overrideProject) {
          setProjects((projects) =>
            projects.map((p) =>
              p.id === project.id ? { ...project, remoteId: id } : p
            )
          );
        } else {
          return;
        }

        ReactGA.event({
          category: 'CableTracker.global',
          action: 'click',
          label: 'load_project',
        });
      } else {
        setProjects((projects) => [...projects, { ...project, remoteId: id }]);
      }

      setCurrentConfigurationId(project.activeConfig);
      setCurrentProjectId(project.id);
    } catch (err) {
      console.error(err);
    }
  };

  const loadConfig: ProjectsContextValue['loadConfig'] = (
    projectId,
    configId,
    subProjectId,
    subProjectType
  ) => {
    ReactGA.event({
      category: 'CableTracker.global',
      action: 'click',
      label: 'load_configuration',
    });
    const project = projects.find((p) => p.id === projectId);
    project &&
      updateRemoteState({
        ...project,
        activeConfig: configId,
      });

    setCurrentConfigurationId(configId);
    setCurrentProjectId(projectId);

    if (subProjectType) {
      if (subProjectType === 'subProjects') {
        setCurrentSubProjectId(subProjectId || null);
        setCurrentPendantId(null);
      } else {
        setCurrentPendantId(subProjectId || null);
        setCurrentSubProjectId(null);
      }
    }

    if (!subProjectId || !subProjectType) {
      setCurrentSubProjectId(null);
      setCurrentPendantId(null);
    }
    setStep(1);
  };

  const applyChanges = <S, K extends keyof S>(
    state: S,
    changes: Pick<S, K>
  ): S => Object.assign({}, state, changes);

  const resetOptionalInputs = () => {
    return {
      lid: null,
      lidLength: null,
      coverJointLength: null,
      dividerLength: null,
      divider: null,
      suspension: null,
      mountType: null,
      dutyType: null,
      suspensionDistance: null,
      // pendantShape: null,
      // pendantHookCount: null,
      // pendantDutyType: null,
      // pendantSupportDistance: null,
      // pendant: null,
      // pendantProjectName: null,
      dataGutter: null,
      length: null,
      bends: [],
      accessories: [],
      size: null,
      coupler: null,
      gutter: null,
      gutterObj: null,
    };
  };

  const setConfig = (
    input: Partial<MultiStepFormState['data']>,
    advanceToNextStep: boolean
  ) => {
    if (currentSubProjectId || currentPendantId) {
      const subProjectType = currentSubProjectId ? 'subProjects' : 'pendants';
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === currentProjectId) {
            const newProjectState = {
              ...project,
              [subProjectType]: project[subProjectType].map((subProject) => {
                if (subProject.id === currentSubProjectId || currentPendantId) {
                  return {
                    ...subProject,
                    configurations: subProject.configurations.map((conf) =>
                      conf.id === currentConfigurationId
                        ? {
                            ...conf, // @ts-ignore for now
                            data: applyChanges(conf.data, {
                              ...input, // @ts-ignore for now
                              ...(input.shape && input.shape !== conf.data.shape
                                ? resetOptionalInputs()
                                : {}),
                            }),
                          }
                        : conf
                    ),
                  };
                } else return subProject;
              }),
            };

            updateRemoteState({
              ...newProjectState,
              activeConfig: currentConfigurationId,
            });

            return newProjectState;
          } else return project;
        })
      );
    } else {
      setProjects((projects) =>
        projects.map((project) => {
          if (project.id === currentProjectId) {
            const newProjectState = {
              ...project,
              configurations: project.configurations.map((conf) =>
                conf.id === currentConfigurationId
                  ? {
                      ...conf, // @ts-ignore for now
                      data: applyChanges(conf.data, {
                        ...input, // @ts-ignore for now
                        ...(input.shape && input.shape !== conf.data.shape
                          ? resetOptionalInputs()
                          : {}),
                      }),
                    }
                  : conf
              ),
            };
            updateRemoteState({
              ...newProjectState,
              activeConfig: currentConfigurationId,
            });

            return newProjectState;
          } else return project;
        })
      );
    }

    advanceToNextStep && setStep((step) => step + 1);
  };

  const goBack = () => {
    ReactGA.event({
      category: 'CableTracker.global',
      action: 'back',
      label: 'step_' + (step - 1),
    });
    setStep((step) => step - 1);
  };

  const goForth = () => {
    ReactGA.event({
      category: 'CableTracker.global',
      action: 'next',
      label: 'step_' + (step + 1),
    });
    setStep((step) => step + 1);
  };

  const getLanguageParamKey = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageParam = urlParams.get('language') || '';
    return languageParam;
  };
  const getDistributorParamKey = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const distributorParam = urlParams.get('distributor') || '';
    return distributorParam;
  };
  const isValidDistributor = () => {
    const ValidEnumDistributorList = ['es'];
    const countryKey = getDistributorParamKey();
    return ValidEnumDistributorList.includes(countryKey);
  };
  const isValidEnumCondition = () => {
    const isValidLanguage = () => {
      const ValidEnumLanguageList = ['sv-se'];
      const languageKey = getLanguageParamKey();
      return ValidEnumLanguageList.includes(languageKey);
    };

    return isValidLanguage() || isValidDistributor();
  };

  useEffect(() => {
    localStorage.setItem('projects', JSON.stringify(projects));
  }, [projects]);

  useEffect(() => {
    localStorage.setItem('currentProjectId', JSON.stringify(currentProjectId));
  }, [currentProjectId]);

  useEffect(() => {
    localStorage.setItem(
      'currentConfigurationId',
      JSON.stringify(currentConfigurationId)
    );
  }, [currentConfigurationId]);

  useEffect(() => {
    localStorage.setItem(
      'currentSubProjectId',
      JSON.stringify(currentSubProjectId)
    );
  }, [currentSubProjectId]);

  useEffect(() => {
    localStorage.setItem('currentPendantId', JSON.stringify(currentPendantId));
  }, [currentPendantId]);

  return (
    <ProjectsContext.Provider
      value={
        {
          step,
          goBack,
          goForth,
          projects,
          currentProject: getProject(projects, currentProjectId),
          currentSubProject: getProject(
            projects,
            currentProjectId,
            'subProjects',
            currentSubProjectId
          ),
          currentPendant: getProject(
            projects,
            currentProjectId,
            'pendants',
            currentPendantId
          ),
          currentConfiguration: getConfig(
            projects,
            currentProjectId,
            currentConfigurationId,
            currentSubProjectId ? 'subProjects' : 'pendants',
            currentSubProjectId || currentPendantId
          ),
          createProject,
          editProject,
          deleteProject,
          duplicateProject,
          createProjectItem,
          editProjectItem,
          duplicateProjectItem,
          deleteProjectItem,
          setCurrentProjectId,
          isValidEnumCondition,
          isValidDistributor,
          getLanguageParamKey,
          setConfig,
          loadProject,
          loadConfig,
          getBackwardsCompatibleData,
          setStep,
          previousStep,
          addConfigToPendant,
        } as ProjectsContextValue
      }
    >
      {children}
    </ProjectsContext.Provider>
  );
};
