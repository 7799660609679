import './styles.css';
import { useState } from 'react';
import Checkbox from '../Checkbox';
import { useTranslation } from 'react-i18next';
import { useMyContext, calcNumberOfRequiredPieces } from 'helpers';
import {
  Pendant,
  Suspension,
  WallDivider,
} from 'components/MultiStepForm/types';
import { useModalState } from 'hooks';
import Modal from '../Modal';
import SuspensionsModal from './components/SuspensionsModal';
import findSuspension from 'helpers/findSuspension';

type OptionalInputProps =
  | ({
      children?: undefined;
      label: string;
      toggleText: string | React.ReactNode;
      unitLength?: string;
      onToggleVisible?: never;
      isInitiallyOpen?: never;
      supportDistances?: string[];
    } & (
      | {
          name: 'suspension';
          type: 'text';
          additionalOptions: Suspension[];
          pendants: Pendant[];
          supportDistances: string[];
        }
      | {
          name: 'lidLength';
          type: 'number';
          additionalOptions?: never;
          pendants?: never;
          supportDistances?: never;
        }
      | {
          name: 'dividerLength';
          type: 'number';
          additionalOptions: WallDivider[];
          pendants?: never;
          supportDistances?: never;
        }
      | {
          name: 'coverJointLength';
          type: 'number';
          additionalOptions?: never;
          pendants?: never;
          supportDistances?: never;
        }
    ))
  | {
      children?: React.ReactNode;
      label?: never;
      toggleText: string | React.ReactNode;
      isInitiallyOpen?: boolean;
      onToggleVisible?: (visible: boolean) => void;
      unitLength?: never;
      name?: never;
      type?: never;
      additionalOptions?: never;
      pendants?: never;
      supportDistances?: never;
    };

const OptionalInput = ({
  label,
  toggleText,
  type,
  name,
  unitLength,
  additionalOptions,
  children,
  pendants,
  isInitiallyOpen,
  supportDistances,
  onToggleVisible,
}: OptionalInputProps) => {
  const { t } = useTranslation();
  const [isOpen, openModal, closeModal] = useModalState({
    coverOrDivider: false,
    suspension: false,
  });
  const { setConfig, currentConfiguration } = useMyContext<3>();
  const { mountType, suspension } = currentConfiguration?.data || {};
  const [visible, setIsVisible] = useState(
    isInitiallyOpen
      ? isInitiallyOpen
      : name
      ? !!currentConfiguration?.data[name]
      : false
  );

  const lidOrDivider = name === 'lidLength' ? 'lid' : 'divider';

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsVisible(e.target.checked);
    name === 'suspension' && !!e.target.checked && openModal('suspension');

    if (!e.target.checked && name) {
      const newConfVal = {
        [name]: null,
      };
      if (name === 'lidLength' || name === 'dividerLength') {
        newConfVal[lidOrDivider] = null;
        newConfVal[lidOrDivider + 'Length'] = null;
      }

      setConfig(newConfVal, false);
    } else {
      if (name === 'lidLength' || name === 'dividerLength') {
        if (additionalOptions && !currentConfiguration.data[lidOrDivider]) {
          const length = (lidOrDivider + 'Length') as
            | 'lidLength'
            | 'dividerLength';
          setConfig(
            {
              [lidOrDivider]: additionalOptions[0].cr,
              [length]:
                currentConfiguration.data[length] ||
                currentConfiguration.data.length,
            },
            false
          );
        }
      }
    }
  };

  const roundToDivisibleByN = (input: number, n: number) =>
    Math.ceil(input / n) * n;

  const getSelectedAdditionalOption = () => {
    if (name === 'lidLength' || name === 'dividerLength') {
      return additionalOptions?.find(
        (o) => o.cr === currentConfiguration?.data[lidOrDivider]
      );
    } else return null;
  };

  return (
    <>
      <label className="optional-input">
        <strong className="center-y">
          <Checkbox
            checked={visible}
            onChange={(e) => {
              handleCheckboxChange(e);
              onToggleVisible && onToggleVisible(e.target.checked);
            }}
          />
          {typeof toggleText !== 'string' ? (
            toggleText
          ) : (
            <span>
              {toggleText} ({t('global.optional')})
            </span>
          )}
        </strong>
        {visible &&
          (children
            ? children
            : name !== undefined && (
                <>
                  <strong onClick={(e) => e.preventDefault()}>{label}</strong>
                  <div>
                    <input
                      type={type}
                      onClick={() =>
                        name === 'suspension' && openModal('suspension')
                      }
                      value={
                        name === 'suspension'
                          ? findSuspension(
                              additionalOptions,
                              mountType,
                              suspension
                            )?.crDescription
                          : currentConfiguration?.data[name] || ''
                      }
                      onChange={(e) => {
                        name !== 'suspension' &&
                          setConfig(
                            {
                              [name]:
                                // @ts-ignore
                                type === 'text'
                                  ? e.target.value
                                  : parseInt(e.target.value),
                            },
                            false
                          );
                      }}
                      onBlur={() => {
                        const length = currentConfiguration?.data[name];

                        name !== 'suspension' &&
                          length &&
                          setConfig(
                            {
                              [name]: roundToDivisibleByN(
                                parseInt(`${length}`),
                                parseInt(
                                  getSelectedAdditionalOption()?.length || '1'
                                )
                              ),
                            },
                            false
                          );
                      }}
                    />
                    {type === 'number' && (
                      <span
                        className="measurement-unit"
                        onClick={(e) => e.preventDefault()}
                      >
                        m
                      </span>
                    )}
                  </div>
                  {unitLength &&
                    (name === 'lidLength' || name === 'dividerLength') && (
                      <span
                        className="text-secondary"
                        onClick={(e) => e.preventDefault()}
                      >
                        <>
                          {t('main.step3.num.of.pcs.required')}{' '}
                          {calcNumberOfRequiredPieces(
                            getSelectedAdditionalOption()?.length,
                            currentConfiguration?.data[name]
                          )}{' '}
                          x {getSelectedAdditionalOption()?.length}m
                        </>
                      </span>
                    )}
                  {additionalOptions && name !== 'suspension' && (
                    <div
                      className="additional-options"
                      onClick={(e) => {
                        e.preventDefault();
                        openModal('coverOrDivider');
                      }}
                    >
                      {t('main.step3.include.additionaloptions')}
                    </div>
                  )}
                </>
              ))}
      </label>
      {additionalOptions &&
        (name !== 'suspension' ? (
          <Modal
            isOpen={isOpen['coverOrDivider']}
            className="additional-options-modal"
          >
            <Modal.Header onClose={() => closeModal('coverOrDivider')}>
              {t('global.choose.walldivider.type')}
            </Modal.Header>
            <Modal.Content>
              {additionalOptions.map(
                ({ imageUrl, name: optionName, crDescription, cr }) => {
                  return (
                    <div
                      key={`option-${optionName}-${cr}`}
                      className="additional-option"
                      style={{
                        borderColor:
                          cr === currentConfiguration?.data[lidOrDivider]
                            ? 'var(--accent-color)'
                            : '',
                      }}
                      onClick={() => {
                        setConfig({ [lidOrDivider]: cr }, false);
                        closeModal('coverOrDivider');
                      }}
                    >
                      <img src={imageUrl} alt="" />
                      <div>
                        <strong>{optionName}</strong>
                        <p>{crDescription}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </Modal.Content>
            <Modal.Footer>
              <button
                onClick={() => closeModal('coverOrDivider')}
                className="btn-tertiary"
              >
                {t('global.cancel')}
              </button>
            </Modal.Footer>
          </Modal>
        ) : (
          <SuspensionsModal
            isOpen={isOpen['suspension']}
            onClose={() => closeModal('suspension')}
            suspensions={additionalOptions}
            supportDistances={supportDistances}
            pendants={pendants}
            requiredPieces={calcNumberOfRequiredPieces(
              unitLength,
              currentConfiguration.data.length
            )}
          />
        ))}
    </>
  );
};

export default OptionalInput;
