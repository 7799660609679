import './styles.css';
import { useContext } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import InstallationGuideModal from '../InstallationGuideModal';
import { useModalState } from 'hooks';
import { Loader, ProductThumbnail } from 'shared/components';
import { generateStateFromArray, useMyContext } from 'helpers';
import { ApiHashContext } from 'contexts/apiHashContext';
import { API_HOST } from 'shared/constants';

type ShapeData = {
  name: string;
  widthRange: string;
  heightRange: string;
  lengthRange: string;
  productType: 'Mesh Tray' | 'Cable Ladder';
  surfaceTreatment: string;
  imageUrl: string;
};

type Step2ApiRes = {
  availableSeries: ShapeData[];
};

const ShapeForm = () => {
  const { t } = useTranslation();
  const { currentConfiguration, setConfig, goBack } = useMyContext<2>();
  const { material, shape: selectedShape } = currentConfiguration.data;
  const { apiHash } = useContext(ApiHashContext);
  const { isLoading, error, data } = useQuery(
    'step2Data',
    () =>
      axios
        .post<Step2ApiRes>(`${API_HOST}/wibe/step2/${apiHash}`, {
          selectedClassification: material,
        })
        .then((res) => res.data.availableSeries),
    { refetchOnWindowFocus: false }
  );
  const [modals, openModal, closeModal] = useModalState({
    ...generateStateFromArray(data || [], 'name', false),
  });

  if (isLoading) return <Loader />;
  if (error)
    return (
      <p className="center-x api-error">
        {t('fetching.product.series.error.msg')}
      </p>
    );

  const categories = [...new Set(data?.map((s) => s.productType))];
  const categorizedShapes = categories.map((c) => {
    return {
      name: c,
      shapes: data?.filter((s) => s.productType === c),
    };
  });

  return (
    <div className="shape-form">
      <h2>{t('main.step2.select.shape')}</h2>
      <div className="shapes-container">
        {data?.length ? (
          categorizedShapes.map(({ name, shapes }) => (
            <div className="shape-category" key={`option-${name}`}>
              <p className="shape-category-name">
                <>
                  <img
                    src="https://nu-platform-prod-2.storage.googleapis.com/gallery/ef97485b1ba64abaad06cf637dc0c078.png"
                    alt={'shape-category-img'}
                  />
                  {name}
                </>
              </p>
              <div className="shape-category-products">
                {shapes?.map((shape) => (
                  <div
                    key={`option-${shape.name}-${shape.widthRange}-${shape.heightRange}-${shape.lengthRange}`}
                  >
                    <div
                      onClick={() => {
                        ReactGA.event({
                          category: 'CableTracker.shapes',
                          action: 'select_shape',
                          label: shape.name,
                        });
                        setConfig({ shape: shape.name }, true);
                      }}
                    >
                      <div className="product-name">{shape.name}</div>
                      <p className="text-secondary">
                        {t('second.step.item.width')}: {shape.widthRange}mm
                      </p>
                      <p className="text-secondary">
                        {t('second.step.item.height')}: {shape.heightRange}mm
                      </p>
                      <p className="text-secondary">
                        {t('second.step.item.length')}: {shape.lengthRange}mm
                      </p>

                      <ProductThumbnail
                        img={shape.imageUrl}
                        text={
                          t('backend.installation.guide') ||
                          'Installation guide'
                        }
                        onClick={() => openModal(shape.name)}
                        selected={shape.name === selectedShape}
                      />
                    </div>
                    <InstallationGuideModal
                      isOpen={modals[shape.name]}
                      onClose={() => closeModal(shape.name)}
                      name={shape.name}
                      width={shape.widthRange}
                      treatment={shape.surfaceTreatment}
                      type={name}
                      img={shape.imageUrl}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="center-x ">{t('no.product.series.found.error.msg')}</p>
        )}
      </div>
      <div className="multi-step-form-footer">
        <button className="btn-secondary" onClick={goBack}>
          {t('global.previous')}
        </button>
      </div>
    </div>
  );
};

export default ShapeForm;
