import './styles.css';
import { useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useModalState } from 'hooks';
import ProjectCodeModal from '../ProjectCodeModal';
import SubprojectModal from '../SubprojectModal';
import UpsertConfigurationModal from '../UpsertConfigurationModal';
import EditProjectModal from '../EditProjectModal';
import ProjectItem from '../MyProjectItem';
import { useMyContext } from 'helpers';
import { Tooltip } from 'shared/components';
import {
  ProjectCode,
  AddSubProject,
  ProjectOverview,
  AddConfiguration,
  EditItem,
  DeleteItem,
} from 'assets/icons/projectItem';

import type { Configuration, Pendant, SubProject } from 'shared/types';

interface MyProjectProps {
  id: string;
  name: string;
  subProjects?: SubProject[];
  configurations: Configuration[];
  pendants: Pendant[];
  onClose: () => void;
}

const MyProject = ({
  id,
  name,
  configurations,
  subProjects,
  pendants,
  onClose,
}: MyProjectProps) => {
  const {
    deleteProject,
    currentProject,
    currentSubProject,
    currentPendant,
    currentConfiguration,
    editProject,
    setStep,
  } = useMyContext();
  const { t } = useTranslation();
  const [showConfigurations, setShowConfigurations] = useState(
    currentProject?.id === id
  );
  const [modals, openModal, closeModal] = useModalState({
    projectCode: false,
    subproject: false,
    newConfiguration: false,
    editProject: false,
  });

  const toggleShowConfigurations = () => {
    ReactGA.event({
      category: 'CableTracker.myProject',
      action: 'click',
      label: 'show_configurations',
    });
    setShowConfigurations(!showConfigurations);
  };

  const isOnlyProjectItem =
    !subProjects?.length && !pendants.length && !configurations.length;

  return (
    <div className="my-project">
      <div>
        <div className="project-name" onClick={toggleShowConfigurations}>
          <MdExpandMore
            className="text-accent"
            style={{ transform: showConfigurations ? '' : 'rotate(-90deg)' }}
          />
          {name}
        </div>
        <div className="center-y">
          <Tooltip
            content={t('project.modal.item.project.code') || 'Project code'}
          >
            <div className="center-y" onClick={() => openModal('projectCode')}>
              <ProjectCode />
            </div>
          </Tooltip>
          <Tooltip
            content={t('project.modal.item.new.subproject') || 'New subproject'}
          >
            <div className="center-y" onClick={() => openModal('subproject')}>
              <AddSubProject />
            </div>
          </Tooltip>
          <Tooltip
            content={
              t('project.modal.item.project.overview') || 'Project overview'
            }
          >
            <div
              className="center-y"
              onClick={() => {
                ReactGA.event({
                  category: 'CableTracker.myProject',
                  action: 'click',
                  label: 'project_overview',
                });
                setStep(5);
                onClose();
              }}
            >
              <ProjectOverview />
            </div>
          </Tooltip>
          <Tooltip
            content={
              t('project.modal.item.add.configuration') || 'Add configuration'
            }
          >
            <div
              className="center-y"
              onClick={() => openModal('newConfiguration')}
            >
              <AddConfiguration />
            </div>
          </Tooltip>
          <Tooltip
            content={t('project.modal.item.rename.project') || 'Rename project'}
          >
            <div className="center-y" onClick={() => openModal('editProject')}>
              <EditItem />
            </div>
          </Tooltip>

          {currentProject?.id !== id && (
            <Tooltip
              content={
                t('project.modal.item.delete.project') || 'Delete project'
              }
            >
              <div
                className="center-y"
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.myProject',
                    action: 'click',
                    label: 'delete_project',
                  });
                  deleteProject(id);
                }}
              >
                <DeleteItem />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {showConfigurations && (
        <div className="configurations-container">
          {subProjects?.map(({ name, id: subProjectId, configurations }) => (
            <ProjectItem
              type="subProjects"
              projectId={id}
              id={subProjectId}
              name={name}
              isActive={
                currentSubProject?.id === subProjectId || isOnlyProjectItem
              }
              configurations={configurations}
              currentConfiguration={currentConfiguration?.id || ''}
            />
          ))}
          {pendants?.map(({ name, id: pendantId, configurations }) => (
            <ProjectItem
              type="pendants"
              projectId={id}
              id={pendantId}
              name={name}
              isActive={currentPendant?.id === pendantId || isOnlyProjectItem}
              configurations={configurations}
              currentConfiguration={currentConfiguration?.id || ''}
            />
          ))}
          {configurations.map(({ id: configId, name }) => (
            <ProjectItem
              type="configurations"
              id={configId}
              projectId={id}
              name={name}
              isActive={
                currentConfiguration?.id === configId || isOnlyProjectItem
              }
            />
          ))}
        </div>
      )}

      <ProjectCodeModal
        isOpen={modals.projectCode}
        onClose={() => closeModal('projectCode')}
        code={currentProject?.remoteId || ''}
      />
      <SubprojectModal
        subProjectType="subProjects"
        action="create"
        projectId={id}
        isOpen={modals.subproject}
        onClose={() => closeModal('subproject')}
        configurations={configurations}
      />
      <UpsertConfigurationModal
        action="create"
        isOpen={modals.newConfiguration}
        onClose={() => closeModal('newConfiguration')}
        project={id}
      />
      <EditProjectModal
        isOpen={modals.editProject}
        onClose={() => closeModal('editProject')}
        onEdit={(name) => editProject(id, name)}
        initialProjectName={name}
      />
    </div>
  );
};

export default MyProject;
