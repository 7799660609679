import './styles.css';
import { useEffect, useState } from 'react';
import { useMyContext } from 'helpers';
import { Modal, OptionalInput, ProductThumbnail } from 'shared/components';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import {
  Pendant as PendantIcon,
  Ceiling,
  Wall,
  Floor,
} from 'assets/icons/suspensions';
import PendantSteps from '../PendantSteps';

import type { Pendant, Suspension } from 'components/MultiStepForm/types';
import type { ModalProps } from 'shared/types';

const mountTypeIcons: Record<string, React.ReactNode> = {
  'Ceiling suspension': <Ceiling />,
  'Wall suspension': <Wall />,
  'Pendant suspension': <PendantIcon />,
  'Floor suspension': <Floor />,
};

interface SuspensionModalProps extends ModalProps {
  suspensions: Suspension[];
  pendants: Pendant[];
  requiredPieces: number;
  supportDistances: string[];
}

type SuspensionSteps = 1 | 2 | 3;

const SuspensionsModal = ({
  isOpen,
  onClose,
  suspensions,
  requiredPieces,
  pendants,
  supportDistances,
}: SuspensionModalProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<SuspensionSteps>(1);
  const { setConfig, currentConfiguration } = useMyContext<3>();
  const {
    suspension,
    mountType,
    dutyType: selectedDutyType,
    suspensionDistance,
    pendant,
    suspensionObj,
  } = currentConfiguration.data;

  const mountTypes = suspensions.filter(
    (suspension, i, arr) =>
      arr.findIndex((s) => s.mountType === suspension.mountType) === i
  );

  useEffect(() => {
    !isOpen && setStep(1);
  }, [isOpen]);

  const modalTitles = {
    1: t('global.choose.suspension'),
    2: t('global.choose.suspension.type'),
    3: t('global.choose.bracket'),
  };

  const selectedBracketVariations = suspensions
    .filter((s) => s.mountType === mountType && s.dutyType === selectedDutyType)
    .sort((a, b) => parseInt(a.width || '') - parseInt(b.width || ''));

  const selectedBracket =
    selectedBracketVariations.find((b) => b.cr === suspension) ||
    selectedBracketVariations[0];

  const goBack = () => {
    ReactGA.event({
      category: 'CableTracker.suspensionsModal',
      action: 'back',
      label: 'step_' + (step - 1),
    });
    setStep((step) => (step - 1) as SuspensionSteps);
  };

  const goForth = () => {
    ReactGA.event({
      category: 'CableTracker.suspensionsModal',
      action: 'next',
      label: 'step_' + (step + 1),
    });
    setStep((step) => (step + 1) as SuspensionSteps);
  };

  const isDisabled = () => {
    if (step === 1) {
      return !mountType;
    }
    if (step === 2) {
      return !selectedBracket;
    }
  };

  useEffect(() => {
    if (suspension && suspensionObj && !mountType && !selectedDutyType)
      setConfig(
        {
          mountType: suspensionObj.mountType,
          dutyType: suspensionObj.dutyType,
        },
        false
      );
  }, [mountType, suspension, selectedDutyType, suspensionObj, setConfig]);

  return (
    <Modal isOpen={isOpen} className="suspensions-modal">
      {step === 1 && (
        <>
          <Modal.Header onClose={onClose}>{modalTitles[1]}</Modal.Header>
          <Modal.Content>
            {mountTypes.map((type) => (
              <div
                key={`option-${type.cr}-${type.mountType}`}
                className="mount-type"
                onClick={() => {
                  ReactGA.event({
                    category: 'CableTracker.step1',
                    action: 'mount_type',
                    label: type.mountType,
                  });
                  setConfig({ mountType: type.mountType }, false);
                  goForth();
                }}
              >
                <p>{type.mountType}</p>
                <ProductThumbnail
                  img={mountTypeIcons[type.mountType]}
                  selected={
                    mountType === type.mountType ||
                    type.mountType === suspensionObj?.mountType
                  }
                />
              </div>
            ))}
            {pendants.length ? (
              <p>{t('tray.additional.modal.footer.msg')}</p>
            ) : null}
          </Modal.Content>
        </>
      )}
      {step !== 1 &&
        (mountType === 'Pendant suspension' && !pendant ? (
          <PendantSteps
            step={step}
            supportDistances={supportDistances || []}
            onClose={onClose}
            pendants={pendants}
            goBack={goBack}
            goForth={goForth}
            setStep={setStep}
          />
        ) : (
          <>
            <Modal.Header onClose={onClose}>{modalTitles[step]}</Modal.Header>
            <Modal.Content className={'suspension-step-' + step}>
              {step === 2 && (
                <>
                  {suspensions
                    .filter((s) => s.mountType === mountType)
                    .filter((s) => {
                      return (
                        s.mountType !== 'Pendant suspension' ||
                        s.compatiblePendantCrs === null ||
                        s.compatiblePendantCrs?.includes(
                          currentConfiguration.data.pendant!
                        )
                      );
                    })
                    .filter(
                      (value, index, self) =>
                        index ===
                        self.findIndex((t) => t.dutyType === value.dutyType)
                    )
                    .map(({ imageUrl, safeWorkingLoad, dutyType, cr }) => (
                      <div
                        key={`option-${cr}-${dutyType}`}
                        className="additional-option"
                        style={{
                          borderColor:
                            dutyType === selectedDutyType
                              ? 'var(--accent-color)'
                              : '',
                        }}
                        onClick={() => {
                          ReactGA.event({
                            category: 'CableTracker.step2',
                            action: 'duty_type',
                            label: dutyType,
                          });
                          setConfig({ dutyType: dutyType }, false);
                        }}
                      >
                        <img src={imageUrl} alt={dutyType} />
                        <div>
                          <strong>{dutyType}</strong>
                          <p>
                            {t('suspension.modal.max.safe.workload')}{' '}
                            {safeWorkingLoad}
                          </p>
                        </div>
                      </div>
                    ))}
                </>
              )}
              {step === 3 && (
                <>
                  <div className="bracket-img">
                    <img
                      src={selectedBracket.imageUrl}
                      alt={selectedBracket?.crDescription}
                    />
                  </div>
                  <div className="bracket-info">
                    <h4>{selectedBracket?.crDescription}</h4>
                    <p
                      className="text-secondary"
                      style={{ height: 'fit-content', marginBottom: 0 }}
                    >
                      {t('suspension.modal.current.suspension.bracket.width')}{' '}
                      {selectedBracket?.width}
                      mm
                    </p>
                    {selectedBracketVariations.length > 1 ? (
                      <OptionalInput
                        toggleText={t(
                          'suspension.modal.change.suspension.bracket.width'
                        )}
                      >
                        <label className="bracket-widths">
                          {selectedBracketVariations.map((b) => (
                            <span
                              key={`option-${b.cr}-${b.width}`}
                              className={`size-box${
                                b.cr === selectedBracket.cr ? '--selected' : ''
                              }`}
                              onClick={(e) => {
                                ReactGA.event({
                                  category: 'CableTracker.step3',
                                  action: 'suspension',
                                  label: b.cr,
                                });
                                setConfig({ suspension: b.cr }, false);
                              }}
                            >
                              {b.width}mm
                            </span>
                          ))}
                        </label>
                      </OptionalInput>
                    ) : null}
                    <label className="suspension-distances">
                      <strong>
                        {t('suspension.modal.suspension.distance.m')}
                      </strong>
                      {supportDistances?.map((d) => (
                        <span
                          key={`option-${d}`}
                          className={`size-box${
                            (
                              suspensionDistance
                                ? suspensionDistance === parseFloat(d)
                                : (selectedBracket.supportDistances
                                    ? supportDistances[0]
                                    : '') === d
                            )
                              ? '--selected'
                              : ''
                          }`}
                          onClick={() => {
                            ReactGA.event({
                              category: 'CableTracker.step3',
                              action: 'suspension_distance',
                              label: d,
                            });
                            setConfig(
                              { suspensionDistance: parseFloat(d) },
                              false
                            );
                          }}
                        >
                          {d}
                        </span>
                      ))}
                    </label>
                    <p className="text-secondary">
                      {t('suspension.modal.min.num.of.brackets.text')}{' '}
                      {requiredPieces}
                    </p>
                  </div>
                </>
              )}
            </Modal.Content>
          </>
        ))}

      {(!(mountType === 'Pendant suspension' && !pendant) || step === 1) && (
        <Modal.Footer>
          <button onClick={onClose} className="btn-tertiary">
            {t('global.cancel')}
          </button>
          <span>
            {step > 1 && (
              <button className="btn-secondary" onClick={goBack}>
                {t('global.previous')}
              </button>
            )}
            {step < 3 && (
              <button disabled={isDisabled()} onClick={goForth}>
                {t('global.next')}
              </button>
            )}
            {step === 3 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setConfig(
                    {
                      suspension: selectedBracket.cr,
                      suspensionObj: selectedBracket,
                      suspensionDistance: parseFloat(
                        `${
                          suspensionDistance ||
                          (selectedBracket.supportDistances
                            ? supportDistances[0]
                            : 'null')
                        }`
                      ),
                    },
                    false
                  );

                  ReactGA.event({
                    category: 'CableTracker.step3.finish',
                    action: 'suspension',
                    label: selectedBracket.cr,
                  });
                  ReactGA.event({
                    category: 'CableTracker.step3.finish',
                    action: 'suspension_object',
                    label: JSON.stringify(selectedBracket),
                  });
                  ReactGA.event({
                    category: 'CableTracker.step3.finish',
                    action: 'suspension_distance',
                    label: `${
                      suspensionDistance ||
                      (selectedBracket.supportDistances
                        ? supportDistances[0]
                        : 'null')
                    }`,
                  });
                  onClose();
                }}
              >
                {t('global.finished')}
              </button>
            )}
          </span>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default SuspensionsModal;
