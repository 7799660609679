import './App.css';

import { NewProjectModal, Navbar, MultiStepForm } from 'components';
import { useModalState } from 'hooks';
import './i18n';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const App = () => {
  const [isNewProjectModalOpen, , closeNewProjectModal] = useModalState(true);
  useEffect(() => {
    ReactGA.initialize('G-FWPT409DDV');
  }, []);

  return (
    <div className="App">
      <Navbar />
      <NewProjectModal
        isOpen={isNewProjectModalOpen}
        onClose={closeNewProjectModal}
      />
      <MultiStepForm />
    </div>
  );
};

export default App;
