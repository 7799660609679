import { createContext, useState, useEffect } from 'react';
import { ConfiguratorDefinitionsByWid } from './configuratorDefinitions';

export const ApiHashContext = createContext({} as { apiHash: string });

interface ApiHashContextProps {
  children: React.ReactNode;
}

const getDistributorQP = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('distributor') || false;
};

const getDistributorHashByQP = () => {
  const distributorQP = getDistributorQP();
  if (!distributorQP) {
    return false;
  }

  if (ConfiguratorDefinitionsByWid.hasOwnProperty(distributorQP)) {
    return ConfiguratorDefinitionsByWid[distributorQP];
  }

  return false;
};

export const ApiHashProvider = ({ children }: ApiHashContextProps) => {
  const [apiHash, setApiHash] = useState('');

  const getApiHash = () => {
    const distributorHashQP = getDistributorHashByQP();
    const defaultHash =
      window.location.href.indexOf('wibe') !== -1
        ? 'f4586ad10ac6b76146c1bf0e0755a8539d313caaa73d777c2eb27b8f41b17b23'
        : '86f56a51090a64e72882fc6f54eeb6326f77e56700c2584aacd181607d96932c';

    return distributorHashQP !== false ? distributorHashQP : defaultHash;
  };

  useEffect(() => {
    setApiHash(getApiHash());
  }, []);

  return apiHash ? (
    <ApiHashContext.Provider value={{ apiHash }}>
      {children}
    </ApiHashContext.Provider>
  ) : null;
};

export default ApiHashProvider;
