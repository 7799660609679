const materials = [
  {
    name: 'classification.items.c1.title',
    application: 'classification.items.c1.desc',
    icon: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/89c273f859a24c009d09d12a4b4e33ac.png',
    extraInfo: [
      'classification.items.c1.details1',
      'classification.items.c1.details2',
      'classification.items.c1.details3',
      'classification.items.c1.details4',
      'classification.items.c1.details5',
    ],
  },
  {
    name: 'classification.items.c2.title',
    application: 'classification.items.c2.desc',
    icon: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/f45e3f2608704a2ebaa7d6745801fe40.png',
    extraInfo: [
      'classification.items.c2.details1',
      'classification.items.c2.details2',
      'classification.items.c2.details3',
      'classification.items.c2.details4',
    ],
  },
  {
    name: 'classification.items.c3.title',
    application: 'classification.items.c3.desc',
    icon: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/7078955369d54d33b6a21f4e9a4d3a46.png',
    extraInfo: [
      'classification.items.c3.details1',
      'classification.items.c3.details2',
      'classification.items.c3.details3',
      'classification.items.c3.details4',
    ],
  },
  {
    name: 'classification.items.c4.title',
    application: 'classification.items.c4.desc',
    icon: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/2c012a9b89964fa7951fc041d571f6b9.png',
    extraInfo: [
      'classification.items.c4.details1',
      'classification.items.c4.details2',
      'classification.items.c4.details3',
      'classification.items.c4.details4',
    ],
  },
  {
    name: 'classification.items.c5i.title',
    application: 'classification.items.c5i.desc',
    icon: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/980d47bb3b2246e4bd3fe67f6aa10a00.png',
    extraInfo: [
      'classification.items.c5i.details1',
      'classification.items.c5i.details2',
      'classification.items.c5i.details3',
    ],
  },
  {
    name: 'classification.items.c5m.title',
    application: 'classification.items.c5m.desc',
    icon: 'https://nu-platform-prod-2.storage.googleapis.com/gallery/00da91ace18645e0abd37556635c3d9c.png',
    extraInfo: [
      'classification.items.c5m.details1',
      'classification.items.c5m.details2',
      'classification.items.c5m.details3',
    ],
  },
] as const;

export default materials;
