export const ConfiguratorDefinitionsByWid: { [key: string]: string } = {
  oosterberg_nl:
    'ca6724eab9d0b613a57a97eafba85dc6a210b39fb90c866d571e20808cda9fdc',
  nextuser_com:
    'cb77ea97307947e5ad756670f51ea07c8eb33f0f7352cc6df44365a4990242df',
  se_nl: 'f4586ad10ac6b76146c1bf0e0755a8539d313caaa73d777c2eb27b8f41b17b23',
  rexelnl: '6472d5c1677640d8b00fd0e5c1357c2b5157f81193012b063134bc027ddc65fe',
  technischeunie_nl:
    '86f56a51090a64e72882fc6f54eeb6326f77e56700c2584aacd181607d96932c',
  itek: 'ca6724eab9d0b613a57a97eafba85dc6a210b39fb90c866d571e20808cda9fdc',
};
