import './styles.css';
import { useState } from 'react';
import { Modal } from 'shared/components';
import { useModalState } from 'hooks';
import { ModalProps } from 'shared/types';
import { ExistingProjectModal } from 'components';
import { useMyContext } from 'helpers';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

interface NewProjectModalProps extends ModalProps {
  onlyCloseButton?: boolean;
}

const NewProjectModal = ({
  isOpen,
  onClose,
  onlyCloseButton,
}: NewProjectModalProps) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({ projectName: '', configurationName: '' });
  const [
    isExitingProjectModalOpen,
    openExistingProjectModal,
    closeExistingProjectModal,
  ] = useModalState(false);
  const { createProject, currentProject } = useMyContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    ReactGA.event({
      category: 'CableTracker.newProjectModal',
      action: 'click',
      label: 'create_project',
    });
    e.preventDefault();
    createProject(form.projectName, form.configurationName);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} className="new-project-modal">
        {onlyCloseButton && <Modal.Header onClose={onClose} onlyCloseButton />}
        <Modal.Content className={currentProject?.id ? 'row-reverse' : 'row'}>
          <section>
            <h2>{t('project.modal.start.new.project')}</h2>
            <p className="text-secondary">
              {t('project.modal.create.new.project.configuration')}
            </p>
            <form onSubmit={handleSubmit}>
              <label>
                <strong>{t('global.project.name')}</strong>
                <input
                  type="text"
                  placeholder={t('placeholder.my.project') || 'My project'}
                  name="projectName"
                  value={form.projectName}
                  onChange={handleChange}
                />
              </label>
              <label>
                <strong>{t('project.configuration.name')}</strong>
                <input
                  type="text"
                  placeholder={
                    t('placeholder.my.configuration') || 'My Configuration'
                  }
                  name="configurationName"
                  value={form.configurationName}
                  onChange={handleChange}
                />
              </label>
              <button disabled={!form.configurationName || !form.projectName}>
                {t('global.save')}
              </button>
            </form>
          </section>
          <section>
            {currentProject?.id && (
              <div>
                <h2>{t('project.modal.continue.selection')}</h2>
                <p className="text-secondary">
                  {t('project.modal.pickup.where.you.left.off')}
                </p>
                <button onClick={onClose}>
                  {t('project.modal.resume.configuration')}
                </button>
              </div>
            )}
            <div>
              <h2>{t('project.modal.code.question')}</h2>
              <p className="text-secondary">
                {t('project.modal.enter.received.project.code')}
              </p>
              <button onClick={openExistingProjectModal}>
                {t('project.modal.apply.project.code')}
              </button>
            </div>
          </section>
        </Modal.Content>
        <ExistingProjectModal
          isOpen={isExitingProjectModalOpen}
          onClose={closeExistingProjectModal}
        />
      </Modal>
    </>
  );
};
export default NewProjectModal;
